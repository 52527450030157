<template functional>
    <span :class="['base-badge', props.theme, props.size, data.staticClass || '']">
        <slot>
            {{ props.content }}
        </slot>
    </span>
</template>

<script>
import { THEMES, SIZES } from '@types/Badge';

export default {
    name: 'BaseBadge',

    props: {
        content: {
            type: [String, Number],
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_DARK,
            validator: value => Object.values(THEMES).includes(value),
        },

        size: {
            type: String,
            default: SIZES.SIZE_NORMAL,
            validator: value => Object.values(SIZES).includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin size-normal() {
    @apply leading-r text-s;
}

.base-badge {
    @apply px-2 inline-flex items-center justify-center text-center font-book uppercase;
    &.primary {
        @apply text-ds-content-accent;
    }

    &.dark {
        @apply text-dark;
    }

    &.tertiary {
        @apply text-tertiary;
    }

    &.information {
        @apply text-information;
    }

    &.small,
    &.small-desktop-normal {
        @apply leading-xs text-xxs;
    }

    &.normal {
        @include size-normal();
    }

    &.big {
        @apply leading-r text-m;
    }
}

@screen lg {
    .base-badge {
        &.small-desktop-normal {
            @include size-normal();
        }
    }
}
</style>
