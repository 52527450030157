<template>
    <ContainerContent class="faq-list">
        <HeaderText
            :tag="HEADER_TEXT_TAG"
            :size="HEADER_TEXT_SIZE_MOBILE"
            :size-desktop="HEADER_TEXT_SIZE_DESKTOP"
            :align="HEADER_TEXT_ALIGN_CENTER"
        >
            {{ heading }}
        </HeaderText>

        <Accordion class="list">
            <slot />
        </Accordion>

        <div class="footer">
            <slot name="footer" />
        </div>
    </ContainerContent>
</template>

<script>
import Accordion from '@molecules/Accordion/Accordion';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_ALIGNS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'FAQList',

    components: {
        HeaderText,
        Accordion,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H2;
        this.HEADER_TEXT_SIZE_MOBILE = HEADER_TEXT_SIZES.L;
        this.HEADER_TEXT_SIZE_DESKTOP = HEADER_TEXT_SIZES.XL;
        this.HEADER_TEXT_ALIGN_CENTER = HEADER_TEXT_ALIGNS.CENTER;
    },
};
</script>

<style lang="scss" scoped>
.faq-list {
    @apply flex flex-col items-center mt-7;

    max-width: theme('customVariables.faqList.containerMaxWidth');

    .list {
        @apply w-full;
    }

    .footer {
        &:deep() {
            .paragraph {
                @apply text-tertiary text-center;
            }

            .text-link {
                @apply underline;
            }
        }
    }

    @screen lg {
        @apply mt-9;
    }
}
</style>
