<template>
    <ContainerContent :id="id || null" class="marketplace-steps">
        <BaseHeading tag="h2" theme="h3" class="heading">
            {{ heading }}
        </BaseHeading>
        <ol class="steps">
            <MarketplaceStep v-if="$scopedSlots.firstStep" class="step">
                <slot name="firstStep" />
            </MarketplaceStep>
            <MarketplaceStep v-if="$scopedSlots.secondStep" class="step">
                <slot name="secondStep" />
            </MarketplaceStep>
            <MarketplaceStep v-if="$scopedSlots.thirdStep" class="step">
                <slot name="thirdStep" />
            </MarketplaceStep>
        </ol>
        <div class="buttons">
            <ButtonLarge :variant="PRIMARY_ACCENT" class="button">
                {{ $t('Join us') }}
            </ButtonLarge>
            <ButtonLarge :to="termsUrl" :variant="SECONDARY" class="button">
                {{ $t('Terms and conditions') }}
            </ButtonLarge>
        </div>
    </ContainerContent>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import MarketplaceStep from '@organisms/MarketplaceSteps/MarketplaceStep';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketplaceSteps',

    components: {
        ButtonLarge,
        BaseHeading,
        ContainerContent,
        MarketplaceStep,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        termsUrl: {
            type: String,
            required: true,
        },

        id: {
            type: String,
            default: null,
        },
    },

    beforeCreate() {
        const { PRIMARY_ACCENT, SECONDARY } = BUTTON_LARGE_VARIANTS;

        this.PRIMARY_ACCENT = PRIMARY_ACCENT;
        this.SECONDARY = SECONDARY;
    },
};
</script>

<style lang="scss" scoped>
.marketplace-steps {
    @apply flex flex-col mt-7;

    max-width: theme('customVariables.marketplaceSteps.maxWidth');

    .heading {
        @apply text-center mb-5;
    }

    .steps {
        @apply flex flex-col;

        counter-reset: marketplace-steps;

        .step {
            counter-increment: marketplace-steps;
        }
    }

    .step {
        @apply mt-4;

        &:first-of-type {
            @apply mt-0;
        }
    }

    .buttons {
        @apply mt-5 flex flex-col justify-center;
    }

    .button {
        @apply mt-3 text-center justify-center;

        &:first-child {
            @apply mt-0;
        }
    }

    @screen md {
        .steps {
            @apply flex-row;
        }

        .step {
            @apply mt-0;
        }

        .buttons {
            @apply flex-row;
        }

        .button {
            @apply mt-0 ml-5;

            &:first-child {
                @apply ml-0;
            }
        }
    }

    @screen lg {
        @apply mt-9;

        .heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
