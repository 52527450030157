<template>
    <CellList
        :variant="CELL_LIST_VARIANT"
        :horizontal-padding="CELL_LIST_PADDING"
        :label="$t('Brand shop')"
        :description="heading"
        :divider="true"
        class="brand-shop-item"
        @click.native="handleBrandShopClick()"
    >
        <template #button>
            <CellListButton />
        </template>
        <template #content>
            <BasePicture
                :width="64"
                :height="64"
                :src="imageUrl"
                :is-lazy="true"
                :alt="brandName"
                class="picture"
            />
        </template>
    </CellList>
</template>

<script>
import { isHttpLink } from '@assets/link';

import BasePicture from '@atoms/BasePicture/BasePicture';

import {
    CellList,
    CellListButton,
    CELL_LIST_VARIANTS,
    CELL_LIST_PADDINGS,
} from '@modivo-ui/components/CellList/v2';

export default {
    name: 'BrandShopItem',

    components: {
        BasePicture,
        CellList,
        CellListButton,
    },

    props: {
        brandName: {
            type: String,
            required: true,
        },

        heading: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        url: {
            type: String,
            required: true,
        },
    },

    computed: {
        isExternalLink() {
            return isHttpLink(this.url);
        },
    },

    beforeCreate() {
        this.CELL_LIST_VARIANT = CELL_LIST_VARIANTS.BASIC;
        this.CELL_LIST_PADDING = CELL_LIST_PADDINGS.PX_0;
    },

    methods: {
        handleBrandShopClick() {
            if (!this.isExternalLink) {
                this.$router.push({ path: this.url });

                return;
            }

            window.location.href = this.url;
        },
    },
};
</script>

<style lang="scss" scoped>
.brand-shop-item {
    @apply cursor-pointer;

    .picture {
        @apply w-ui-13 h-ui-13 items-center;

        &:deep(.image) {
            @apply object-contain;
        }
    }
}
</style>
