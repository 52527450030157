<template>
    <section :style="styles" :class="theme" class="marketing-slider-section">
        <ContainerContent class="marketing-slider-section-container">
            <div class="wrapper">
                <div v-if="smallTitle || sliderTitle || showAllLink" class="top-section">
                    <div v-if="smallTitle || sliderTitle" class="left">
                        <BodyText
                            v-if="smallTitle"
                            :size="BODY_TEXT_SIZE"
                            :tag="BODY_TEXT_TAG"
                            :color="bodyTextColor"
                            class="small-title"
                        >
                            {{ smallTitle }}
                        </BodyText>

                        <HeaderText
                            v-if="sliderTitle"
                            :size="HEADER_TEXT_SIZE"
                            :tag="HEADER_TEXT_TAG"
                            :variant="headerTextVariant"
                            class="slider-title"
                        >
                            {{ sliderTitle }}
                        </HeaderText>
                    </div>

                    <ButtonSmall
                        v-if="showAllLink"
                        :variant="buttonVariant"
                        :to="showAllLink"
                        class="show-all-button-desktop"
                    >
                        {{ showAllCopy }}
                    </ButtonSmall>
                </div>

                <slot @error="handleError()" />

                <ButtonSmall
                    v-if="showAllLink"
                    :to="showAllLink"
                    :variant="buttonVariant"
                    class="show-all-button-mobile"
                >
                    {{ showAllCopy }}
                </ButtonSmall>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import { THEMES } from '@types/MarketingSection';

import { checkIfExistsInValuesMap } from '@assets/props';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonSmall, BUTTON_SMALL_VARIANTS } from '@modivo-ui/components/ButtonSmall/v2';
import {
    BodyText,
    BODY_TEXT_SIZES,
    BODY_TEXT_TAGS,
    BODY_TEXT_COLORS,
} from '@modivo-ui/components/BodyText/v1';
import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_VARIANTS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingSliderSection',

    components: {
        BodyText,
        ContainerContent,
        HeaderText,
        ButtonSmall,
    },

    props: {
        smallTitle: {
            type: String,
            default: '',
        },

        sliderTitle: {
            type: String,
            default: '',
        },

        showAllLink: {
            type: String,
            default: '',
        },

        showAllCopy: {
            type: String,
            default: 'Show all',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        backgroundColor: {
            type: String,
            default: '',
        },
    },

    computed: {
        buttonVariant() {
            return this.theme === THEMES.THEME_LIGHT
                ? BUTTON_SMALL_VARIANTS.PRIMARY
                : BUTTON_SMALL_VARIANTS.PRIMARY_ALTERNATIVE;
        },

        styles() {
            const { backgroundColor } = this;

            return backgroundColor ? { backgroundColor } : null;
        },

        isThemeDark() {
            return this.theme === THEMES.THEME_DARK;
        },

        headerTextVariant() {
            return this.isThemeDark ? HEADER_TEXT_VARIANTS.ALTERNATIVE : HEADER_TEXT_VARIANTS.BASIC;
        },

        bodyTextColor() {
            return this.isThemeDark ? BODY_TEXT_COLORS.ALTERNATIVE : BODY_TEXT_COLORS.BASIC;
        },
    },

    beforeCreate() {
        this.BODY_TEXT_SIZE = BODY_TEXT_SIZES.S;
        this.BODY_TEXT_TAG = BODY_TEXT_TAGS.SPAN;
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.STRONG;
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES.L;
    },
};
</script>

<style lang="scss" scoped>
.marketing-slider-section {
    @apply w-full;
    @apply relative;

    .wrapper {
        @apply py-ui-7;
        @apply w-ui-percent-100;
    }

    .top-section {
        @apply mb-ui-3;
    }

    .small-title {
        @apply block;
        @apply uppercase;
    }

    .slider-title {
        @apply block;
    }

    .show-all-button-mobile {
        @apply mt-ui-1;
        @apply w-ui-percent-100;
    }

    .show-all-button-desktop {
        @apply hidden;
    }

    @screen md {
        .top-section {
            @apply flex justify-between;
        }

        .show-all-button-mobile {
            @apply hidden;
        }

        .show-all-button-desktop {
            @apply inline-flex self-end;
            @apply w-ui-auto;
            @apply m-ui-0;
        }
    }

    @screen lg {
        .marketing-slider-section-container {
            @apply py-ui-9;
        }

        .top-section {
            @apply mb-ui-4;
            @apply mx-ui-auto;

            max-width: #{theme('container.maxWidth')};
        }

        .small-title {
            @apply mb-ui-1;
        }
    }
}
</style>
