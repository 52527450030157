<template>
    <section class="marketing-blog-bar">
        <HeaderText :size="HEADER_TEXT_SIZE.S" :variant="HEADER_TEXT_VARIANT" class="tagline">
            {{ tagline }}
        </HeaderText>
        <HeaderText
            :tag="HEADER_TEXT_TAG"
            :size="HEADER_TEXT_SIZE.XL"
            :variant="HEADER_TEXT_VARIANT"
            :align="HEADER_TEXT_ALIGN"
            class="heading"
        >
            {{ heading }}
        </HeaderText>
        <BaseParagraph class="description">
            {{ description }}
        </BaseParagraph>
        <ButtonLarge :variant="SECONDARY_ALTERNATIVE" class="action" :to="buttonLink">
            {{ buttonLabel }}
        </ButtonLarge>
    </section>
</template>

<script>
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';
import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_VARIANTS,
    HEADER_TEXT_ALIGNS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingBlogBar',

    components: {
        HeaderText,
        BaseParagraph,
        ButtonLarge,
    },

    props: {
        tagline: {
            type: String,
            required: true,
        },

        heading: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        buttonLabel: {
            type: String,
            required: true,
        },

        buttonLink: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.SECONDARY_ALTERNATIVE = BUTTON_LARGE_VARIANTS.SECONDARY_ALTERNATIVE;
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H2;
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES;
        this.HEADER_TEXT_VARIANT = HEADER_TEXT_VARIANTS.ALTERNATIVE;
        this.HEADER_TEXT_ALIGN = HEADER_TEXT_ALIGNS.CENTER;
    },
};
</script>

<style lang="scss" scoped>
.marketing-blog-bar {
    @apply text-center p-ds-7 bg-ds-content-accent;

    .tagline {
        @apply block;
    }

    .heading,
    .description {
        @apply block mt-ds-4 mx-auto;
        max-width: theme('customVariables.marketingBlogBar.textMaxWidth');
    }

    .description {
        @apply text-light text-center;
    }

    .action {
        @apply mt-ds-6;
    }

    @screen md {
        .heading,
        .description {
            @apply mt-ds-2;
        }
    }
}
</style>
