<template>
    <div class="marketing-cards-slider-headings">
        <HeaderText :size="HEADER_TEXT_SIZE.M" class="heading">
            {{ heading }}
        </HeaderText>
        <HeaderText
            :size="HEADER_TEXT_SIZE.M"
            :size-desktop="HEADER_TEXT_SIZE.XL"
            class="sub-heading"
        >
            {{ subHeading }}
        </HeaderText>
    </div>
</template>

<script>
import { HeaderText, HEADER_TEXT_SIZES } from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingCardsSliderHeadings',

    components: {
        HeaderText,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES;
    },
};
</script>

<style lang="scss" scoped>
.marketing-cards-slider-headings {
    @apply flex flex-col px-3;

    .heading {
        @apply text-ds-content-accent;
    }

    @screen lg {
        @apply px-0;
    }

    @screen xl {
        .sub-heading {
            @apply mt-2;
        }
    }
}
</style>
