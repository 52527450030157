<template>
    <span class="label-information" @click.prevent.stop="openAdvertisementModal()">
        <svg :width="svgWidth" height="18" :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">
            <text xml:space="preserve">
                <tspan x="0" y="10">{{ label }}</tspan>
            </text>
        </svg>
        <Information width="20px" height="20px" />
    </span>
</template>

<script>
import { MODAL_ADVERTISEMENT_INFORMATION, MODAL_TYPE } from '@configs/modals';
import { checkIfExistsInValuesMap } from '@assets/props';
import { TYPES } from '@types/AdvertisementModal';
import { Information } from '@modivo-ui/icons/v2/semantic';

export default {
    name: 'LabelInformation',

    components: {
        Information,
    },

    props: {
        companyAccountId: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(TYPES),
        },
    },

    computed: {
        label() {
            return this.$t('Sponsored');
        },
        svgWidth() {
            const averageCharacterWidth = 7;

            return this.label.length * averageCharacterWidth;
        },
        viewBox() {
            return `0 0 ${this.svgWidth} 12`;
        },
    },

    methods: {
        openAdvertisementModal() {
            if (!this.companyAccountId) {
                return;
            }

            this.$modals.open(MODAL_ADVERTISEMENT_INFORMATION, {
                [MODAL_TYPE]: this.type,
                companyAccountId: this.companyAccountId,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.label-information {
    @apply font-ui-body-caption text-ds-content-disabled;
    @apply inline-flex cursor-pointer;

    svg {
        fill: theme('colors.gray6');

        text {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            letter-spacing: inherit;
            fill: inherit;
        }
    }
}
</style>
