<template>
    <ContainerContent class="marketplace-cooperate-with-us">
        <BaseHeading class="heading" tag="h2" theme="h3">
            {{ heading }}
        </BaseHeading>

        <div class="list">
            <slot />
        </div>

        <ButtonLarge v-if="buttonUrl" :variant="SECONDARY" :to="buttonUrl" class="button">
            {{ $t('How does it work?') }}
        </ButtonLarge>
    </ContainerContent>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketplaceCooperateWithUs',

    components: {
        BaseHeading,
        ButtonLarge,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        buttonUrl: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.SECONDARY = BUTTON_LARGE_VARIANTS.SECONDARY;
    },
};
</script>

<style lang="scss" scoped>
.marketplace-cooperate-with-us {
    @apply flex flex-col items-center text-center mt-ds-9;

    .heading {
        @apply mb-ds-7;
    }

    .button {
        @apply mt-ds-7;
    }

    @screen md {
        .list {
            @apply flex justify-center;
        }
    }

    @screen lg {
        @apply mt-[88px];

        .heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
