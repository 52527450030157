<template>
    <div
        :class="theme"
        :style="{
            '--marketing-hero-background-color': bgColor,
        }"
        class="marketing-hero-block-content"
    >
        <HeaderText
            :tag="HEADER_TEXT_TAG"
            :size="HEADER_TEXT_SIZE_MOBILE"
            :size-desktop="HEADER_TEXT_SIZE_DESKTOP"
            class="heading"
        >
            {{ heading }}
        </HeaderText>
        <BaseParagraph v-if="description" class="description">
            {{ description }}
        </BaseParagraph>
        <ButtonLarge v-if="buttonLabel" :variant="buttonVariant" class="button">
            {{ buttonLabel }}
        </ButtonLarge>
    </div>
</template>

<script>
import { checkIfExistsInValuesMap } from '@assets/props';

import { THEMES } from '@types/MarketingBlocks';

import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingHeroBlockContent',

    components: {
        HeaderText,
        BaseParagraph,
        ButtonLarge,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        bgColor: {
            type: String,
            default: '#0000',
        },

        theme: {
            type: String,
            default: THEMES.THEME_DARK,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        buttonVariant: {
            type: String,
            default: BUTTON_LARGE_VARIANTS.SECONDARY,
            validator: checkIfExistsInValuesMap(BUTTON_LARGE_VARIANTS),
        },
    },

    beforeCreate() {
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H3;
        this.HEADER_TEXT_SIZE_MOBILE = HEADER_TEXT_SIZES.L;
        this.HEADER_TEXT_SIZE_DESKTOP = HEADER_TEXT_SIZES.XL;
    },
};
</script>

<style lang="scss" scoped>
$marketing-hero-max-height-mobile: 240px;
$marketing-hero-max-height-desktop: 288px;
$marketing-hero-width: 448px;

.marketing-hero-block-content {
    @apply bg-transparent w-full py-3 flex flex-col;
    max-height: $marketing-hero-max-height-mobile;

    &.light {
        .heading,
        .description {
            @apply text-light;
        }
    }

    &.dark {
        .heading,
        .description {
            @apply text-tertiary;
        }
    }

    .description {
        @apply mt-1 text-ellipsis overflow-hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .button {
        @apply mt-5;
    }

    @screen mobile-only {
        background: var(--marketing-hero-background-color);
    }

    @screen lg {
        @apply block;
        max-height: $marketing-hero-max-height-desktop;
        width: $marketing-hero-width;
        height: fit-content;

        .description {
            -webkit-line-clamp: 3;
        }
    }
}
</style>
