<template>
    <ContainerContent class="marketplace-cooperation-with-modivo">
        <BaseHeading class="heading" tag="h2" theme="h3">
            {{ heading }}
        </BaseHeading>

        <div class="subheading">
            <slot name="subheading" />
        </div>

        <div class="text">
            <slot name="text" />
        </div>

        <ButtonLarge
            class="button"
            :variant="THEME_PRIMARY"
            @click="openJoinModivoMarketplaceModal()"
        >
            {{ $t('Join us') }}
        </ButtonLarge>
    </ContainerContent>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketplaceCooperationWithModivo',

    components: {
        ButtonLarge,
        BaseHeading,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.PRIMARY_ACCENT = BUTTON_LARGE_VARIANTS.PRIMARY_ACCENT;
    },

    methods: {
        openJoinModivoMarketplaceModal() {
            // @todo PM-324 - spięcie z modalem
        },
    },
};
</script>

<style lang="scss" scoped>
.marketplace-cooperation-with-modivo {
    @apply bg-gray5 mt-ds-9 py-ds-7 flex flex-col items-center;

    .heading {
        @apply text-center;
    }

    .subheading {
        @apply mt-ds-6;

        &:deep() {
            .paragraph {
                @apply text-r leading-r text-tertiary text-center;
            }
        }
    }

    .text {
        &:deep() {
            .paragraph {
                @apply mt-ds-2 text-s leading-s text-tertiary text-center;
            }
        }
    }

    .button {
        @apply mt-ds-6;
    }

    @screen mobile-only {
        .button {
            @apply w-ds-percent-100 justify-center;
        }
    }

    @screen lg {
        @apply mt-[88px];

        .subheading {
            &:deep() {
                .paragraph {
                    @apply text-m leading-m;
                }
            }
        }

        .text {
            &:deep() {
                .paragraph {
                    @apply text-r leading-r;
                }
            }
        }

        .heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
