import {
    THEME_PRIMARY,
    THEME_SECONDARY,
    THEME_SECONDARY_DASHED,
    THEME_TERTIARY,
    THEME_INITIAL,
    THEME_PURE,
    THEME_WHITE,
    THEME_TRANSPARENT_WHITE,
    THEME_TRANSPARENT_WHITE_DASHED,
    THEME_THIN_DARK,
    THEME_THIN_GRAY,
    THEME_THIN_LIGHT,
    THEME_DARK,
    THEME_LIGHT,
    THEME_TAG,
    THEME_EOB,
} from '@types/Button';

import { BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

const {
    PRIMARY,
    PRIMARY_ACCENT,
    PRIMARY_ALTERNATIVE,
    SECONDARY,
    SECONDARY_ALTERNATIVE,
} = BUTTON_LARGE_VARIANTS;

export const BASE_BUTTON_THEMES_TO_DS_BUTTON_VARIANTS_MAP = {
    [THEME_PRIMARY]: PRIMARY_ACCENT,
    [THEME_SECONDARY]: SECONDARY,
    [THEME_SECONDARY_DASHED]: SECONDARY,
    [THEME_TERTIARY]: SECONDARY,
    [THEME_INITIAL]: PRIMARY_ALTERNATIVE,
    [THEME_PURE]: PRIMARY_ALTERNATIVE,
    [THEME_WHITE]: PRIMARY_ALTERNATIVE,
    [THEME_TRANSPARENT_WHITE]: SECONDARY_ALTERNATIVE,
    [THEME_TRANSPARENT_WHITE_DASHED]: SECONDARY_ALTERNATIVE,
    [THEME_THIN_DARK]: SECONDARY,
    [THEME_THIN_GRAY]: SECONDARY,
    [THEME_THIN_LIGHT]: SECONDARY_ALTERNATIVE,
    [THEME_DARK]: PRIMARY,
    [THEME_LIGHT]: SECONDARY,
    [THEME_TAG]: PRIMARY_ALTERNATIVE,
    [THEME_EOB]: PRIMARY,
};
