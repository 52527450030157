<template>
    <section ref="slider" class="marketing-promo-categories-slider">
        <div class="slider">
            <ButtonIcon
                v-for="({ isDisabled, direction, icon, cssClass }, key) in navigationButtons"
                :key="key"
                :variant="BUTTON_VARIANT"
                :disabled="isDisabled"
                :class="cssClass"
                class="nav-button"
                @click="onNavigationButtonClick(direction)"
            >
                <template #default>
                    <component :is="icon" />
                </template>
            </ButtonIcon>

            <div ref="wrapper" class="wrapper">
                <slot />
            </div>
        </div>
    </section>
</template>

<script>
import debounce from 'lodash.debounce';

import approximatelyEqual from '@assets/approximatelyEqual';

import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';
import { ChevronRight, ChevronLeft } from '@modivo-ui/icons/v2/navigation';

const SCROLL_DEBOUNCE = 100;
const RESIZE_DEBOUNCE = 500;

export default {
    name: 'MarketingPromoCategoriesSlider',

    components: {
        ButtonIcon,
        ChevronLeft,
        ChevronRight,
    },

    data() {
        return {
            slidesWidth: [],
            currentPosition: 0,
            wrapperScrollWidth: 0,
            wrapperVisibleWidth: 0,
            slideWidth: 0,
            slidesRef: null,
            singleSlide: null,
        };
    },

    computed: {
        navigationButtons() {
            return {
                prev: {
                    isDisabled: this.isBoundedLeft,
                    direction: -1,
                    icon: ChevronLeft,
                    cssClass: 'nav-button-left',
                },

                next: {
                    isDisabled: this.isBoundedRight,
                    direction: 1,
                    icon: ChevronRight,
                    cssClass: 'nav-button-right',
                },
            };
        },

        isBoundedLeft() {
            return approximatelyEqual(this.currentPosition, 0, 5);
        },

        isBoundedRight() {
            return approximatelyEqual(
                this.wrapperScrollWidth - this.wrapperVisibleWidth,
                this.currentPosition,
                5
            );
        },
    },

    mounted() {
        this.init();

        this.attachMutationObserver();

        this.onResizeFn = debounce(this.resizeHandler, RESIZE_DEBOUNCE);
        this.onScrollFn = debounce(this.calcOnScroll, SCROLL_DEBOUNCE);

        this.$refs.wrapper.addEventListener('scroll', this.onScrollFn);
        window.addEventListener('resize', this.onResizeFn, false);
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_ICON_VARIANTS.SECONDARY;
    },

    beforeDestroy() {
        if (this.onResizeFn && this.onScrollFn) {
            this.onResizeFn.cancel();
            this.onScrollFn.cancel();
            this.$refs.wrapper.removeEventListener('scroll', this.onScrollFn);
            window.removeEventListener('resize', this.onResizeFn, false);
            this.onResizeFn = null;
        }
    },

    methods: {
        init() {
            if (this.$refs.wrapper.children.length) {
                if (!this.slidesRef) {
                    this.slidesRef = Array.from(this.$refs.wrapper.children);
                }

                [this.singleSlide] = this.slidesRef;

                this.slideWidth = this.singleSlide.offsetWidth;
            }

            this.calcOnInit();
        },

        resizeHandler() {
            if (!this.onResizeFn) {
                return;
            }

            this.init();
        },

        calcOnInit() {
            this.calcWrapperWidth();
            this.calcSlidesWidth();

            this.calcCurrentPosition();
            this.calcActiveSlide();
        },

        calcOnScroll() {
            if (!this.$refs.wrapper) {
                return;
            }

            this.calcCurrentPosition();
            this.calcActiveSlide();
        },

        calcWrapperWidth() {
            this.wrapperScrollWidth = this.$refs.wrapper.scrollWidth;
            this.wrapperVisibleWidth = this.$refs.wrapper.offsetWidth;
        },

        calcSlidesWidth() {
            const childNodes = [...this.$refs.wrapper.childNodes];

            this.slidesWidth = childNodes.map(node => ({
                offsetLeft: node.offsetLeft,
                width: node.offsetWidth,
            }));
        },

        calcActiveSlide() {
            const { slidesWidth, currentPosition, slidesRef } = this;
            const slides = slidesWidth;
            let activeSlideIndex = 0;

            const activeIndex = slides.findIndex(slide => {
                return approximatelyEqual(slide.offsetLeft, currentPosition, 5);
            });

            if (activeIndex !== -1) {
                activeSlideIndex = Math.max(activeIndex, 0);
            }

            if (slidesRef) {
                slidesRef.forEach(child => {
                    child.classList.remove('active');
                });
                slidesRef[activeSlideIndex].classList.add('active');
            }
        },

        calcCurrentPosition() {
            const { scrollLeft = 0 } = this.$refs.wrapper;

            this.currentPosition = scrollLeft;
        },

        onNavigationButtonClick(direction = 1) {
            this.changeSlide(direction);
        },

        changeSlide(direction = 1) {
            const offset = this.slideWidth;

            this.scroll(direction * offset);
        },

        scroll(position = 0) {
            this.$refs.wrapper.scrollBy({
                left: position,
                behavior: 'smooth',
            });
        },

        attachMutationObserver() {
            this.observer = new MutationObserver(() => {
                this.init();
            });
            this.observer.observe(this.$refs.wrapper, {
                childList: true,
                subtree: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$scrollbar-size: $tailwindcss-spacing-1;

.marketing-promo-categories-slider {
    @apply w-ui-percent-100;

    .slider {
        @apply relative;
        @apply inline-block;
        @apply max-w-ui-percent-100;
        @apply w-ui-percent-100;
    }

    .wrapper {
        @apply overflow-x-scroll overflow-y-hidden;
        @apply w-ui-percent-100;
        @apply grid grid-rows-2 grid-flow-col gap-ui-2;
        @apply justify-start;
        @apply pb-ui-3;
        @apply snap-x snap-mandatory;
        @apply scroll-smooth;

        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar-thumb {
            @apply rounded-ds-container-small;
            @apply bg-ds-container-reverse;
        }

        &::-webkit-scrollbar-track {
            @apply rounded-ds-container-small;
            @apply bg-ds-container-secondary;
        }

        &::-webkit-scrollbar {
            @apply appearance-none;

            height: $scrollbar-size;
        }
    }

    .nav-button {
        @apply hidden;
    }

    @screen md {
        .wrapper {
            @apply gap-ui-4;
        }

        .nav-button {
            @apply absolute top-ui-percent-50 z-ui-1;
            @apply flex items-center justify-center;

            &:disabled {
                @apply hidden;
            }

            &.nav-button-left,
            &.nav-button-right {
                transform: translateY(calc(-50% - 8px));
            }

            &.nav-button-left {
                @apply left-ui-0;
            }

            &.nav-button-right {
                @apply right-ui-0;
            }
        }
    }
}
</style>
