<template>
    <component :is="buttonComponent" :variant="variant" v-bind="propsToBind">
        <slot v-if="slotDefault">{{ JSON.parse(slotDefault) }}</slot>
    </component>
</template>

<script>
import { TYPES, THEMES, SIZES } from '@types/Button';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import { getDesignSystemButtonVariant } from '@assets/base-button-mapping';

import Button from '@mixins/Button';

import { ButtonLarge } from '@modivo-ui/components/ButtonLarge/v1';
import { ButtonSmall } from '@modivo-ui/components/ButtonSmall/v2';

export default {
    name: 'CmsBaseButton',

    components: {
        ButtonLarge,
        ButtonSmall,
    },

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(Button),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    type: TYPES.TYPE_BUTTON,
                    theme: THEMES.THEME_PRIMARY,
                    size: SIZES.SIZE_NORMAL,
                },
                slots: {
                    default: 'example button label',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                type: Object.values(TYPES),
                theme: Object.values(THEMES),
                size: Object.values(SIZES),
            },
        };
    },

    computed: {
        variant() {
            return getDesignSystemButtonVariant(this.theme);
        },

        buttonComponent() {
            return this.size === SIZES.SIZE_SMALL ? ButtonSmall : ButtonLarge;
        },
    },
};
</script>
