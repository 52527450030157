import { render, staticRenderFns } from "./ProductsSimpleSliderSlide.vue?vue&type=template&id=cfdd1e94&scoped=true"
import script from "./ProductsSimpleSliderSlide.vue?vue&type=script&lang=js"
export * from "./ProductsSimpleSliderSlide.vue?vue&type=script&lang=js"
import style0 from "./ProductsSimpleSliderSlide.vue?vue&type=style&index=0&id=cfdd1e94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfdd1e94",
  null
  
)

export default component.exports