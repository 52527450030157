<template>
    <div
        :class="{ 'is-double-height': isDoubleHeight }"
        class="marketing-promo-categories-slider-item"
    >
        <BaseLink :path="link" class="item-link">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :class="{ 'has-image-cover': hasImageCover }"
                :is-lazy="true"
                class="item-img"
            />

            <span :class="{ 'has-light-text': hasLightText }" class="item-title">
                {{ title }}
            </span>
        </BaseLink>
    </div>
</template>

<script>
import {
    IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h,
    IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h,
} from '@types/Image';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BasePicture from '@atoms/BasePicture/BasePicture';

export default {
    name: 'MarketingPromoCategoriesSliderItem',

    components: {
        BaseLink,
        BasePicture,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },

        imageSrc: {
            type: String,
            required: true,
        },

        isDoubleHeight: {
            type: Boolean,
            default: false,
        },

        hasLightText: {
            type: Boolean,
            default: false,
        },

        hasImageCover: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageSrc,
                'promo-categories',
                this.title,
                this.imageFormat
            );
        },

        imageFormat() {
            if (this.hasImageCover && this.isDoubleHeight) {
                return IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h;
            }

            return IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h;
        },
    },
};
</script>

<style lang="scss" scoped>
$item-width: 150px;
$item-height: 150px;
$item-size-lg: 300px;

$item-double-height: 308px;
$item-double-height-lg: 616px;

$img-size-double: 140px;
$img-size-double-lg: 200px;
$img-size: 70px;
$img-size-lg: 140px;

.marketing-promo-categories-slider-item {
    @apply rounded-ds-container-small;
    @apply relative;
    @apply flex;
    @apply bg-ds-container-primary;
    @apply overflow-hidden;

    width: $item-width;
    height: $item-height;
    scroll-snap-align: start;
    transition-duration: 200ms;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        transform: translateY(theme('customVariables.marketingSlider.translateValue'));

        .item-img {
            @apply opacity-ui-container-1;
        }
    }

    &.is-double-height {
        grid-row: 1 / 3;
        height: $item-double-height;

        .item-img {
            width: $img-size-double;
            height: $img-size-double;

            &.has-image-cover {
                @apply mt-ui-0;
                @apply w-ui-percent-100 h-ui-percent-100;
                @apply rounded-ds-container-small;
            }
        }

        .item-link {
            @apply justify-center;
        }
    }

    .item-link {
        @apply flex flex-col justify-start items-center;
        @apply w-ui-percent-100;
        @apply cursor-pointer;

        &:hover {
            @apply no-underline;
        }
    }

    .item-img {
        @apply mx-ui-auto;
        @apply mt-ui-1;
        @apply overflow-hidden;

        width: $img-size;
        height: $img-size;

        &.has-image-cover {
            @apply mt-ui-0;
            @apply w-ui-percent-100 h-ui-percent-100;
            @apply rounded-ds-container-small;
        }
    }

    .item-title {
        @apply text-center;
        @apply absolute bottom-ui-6;
        @apply font-ui-body-m;
        @apply text-ds-content-primary;

        &.has-light-text {
            @apply text-ds-content-reverse;
        }
    }
}

@screen mobile-only {
    .marketing-promo-categories-slider-item {
        .item-img {
            &:deep(.image) {
                @apply w-ui-percent-100;
                @apply h-ui-auto;
            }
        }
    }
}

@screen lg {
    .marketing-promo-categories-slider-item {
        width: $item-size-lg;
        height: $item-size-lg;

        &.is-double-height {
            height: $item-double-height-lg;

            .item-img:not(.has-image-cover) {
                @apply mt-ui-0;
                width: $img-size-double-lg;
                height: $img-size-double-lg;
            }
        }

        .item-img {
            @apply mt-ui-5;
            width: $img-size-lg;
            height: $img-size-lg;
        }

        .item-title {
            @apply font-ui-body-m;
            @apply absolute bottom-ui-9;
        }
    }
}
</style>
