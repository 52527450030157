<template>
    <SimpleSliderSlide class="marketing-image-tiles-simple-slider-slide">
        <BaseLink :path="url">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :is-lazy="true"
                :class="{ mask: isWithMask }"
            />
            <HeaderText
                :tag="HEADER_TEXT_TAG"
                :size="HEADER_TEXT_SIZE"
                :align-desktop="HEADER_TEXT_ALIGN_DESKTOP"
                class="heading"
            >
                {{ heading }}
            </HeaderText>
        </BaseLink>
    </SimpleSliderSlide>
</template>

<script>
import {
    DEFAULT_IMAGE_FORMAT,
    MARKETING_IMAGE_TILE_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLink from '@atoms/BaseLink/BaseLink';

import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';

import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_ALIGNS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingImageTilesSimpleSliderSlide',

    components: {
        HeaderText,
        BasePicture,
        BaseLink,
        SimpleSliderSlide,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        url: {
            type: String,
            required: true,
        },

        isWithMask: {
            type: Boolean,
            defualt: false,
        },

        maskColor: {
            type: String,
            default: '',
        },

        textColor: {
            type: String,
            default: '',
        },

        headingColor: {
            type: String,
            default: '',
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-image-tile',
                `${this.heading}`,
                IMAGE_TYPES.marketing_image_tile_225w_300h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },
    },

    beforeCreate() {
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H5;
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES.S;
        this.HEADER_TEXT_ALIGN_DESKTOP = HEADER_TEXT_ALIGNS.CENTER;
    },
};
</script>

<style lang="scss" scoped>
$item-width-mobile: 148px;
$slider-gap-desktop: 24px;
$item-width-desktop: calc((100% - 5 * #{$slider-gap-desktop}) / 6);
$slider-gap-container: 39px;
$item-width-container: calc((100% - 5 * #{$slider-gap-container}) / 6);

.marketing-image-tiles-simple-slider-slide {
    @apply mr-3 shrink-0;
    width: $item-width-mobile;

    &:first-child {
        @apply ml-3;
    }

    &:last-child {
        @apply pr-3;
        width: calc(#{$item-width-mobile} + #{$tailwindcss-spacing-3});
    }

    &:hover {
        text-decoration-color: v-bind(textColor);
    }

    .heading {
        @apply block p-3;
        background-color: v-bind(headingColor);
        color: v-bind(textColor);
    }

    .mask {
        &:before {
            @apply absolute w-full h-full opacity-50 z-1;
            background-color: v-bind(maskColor);
            content: '';
        }
    }

    @screen lg {
        width: $item-width-desktop;
        margin-right: $slider-gap-desktop;

        &:first-child {
            @apply ml-0;
        }

        &:last-child {
            @apply mr-0 pr-0;
            width: $item-width-desktop;
        }
    }

    @screen container {
        width: $item-width-container;
        margin-right: $slider-gap-container;

        &:last-child {
            width: $item-width-container;
        }
    }
}
</style>
