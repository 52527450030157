<template>
    <div class="marketing-category-slider-heading">
        <HeaderText :size="HEADER_TEXT_SIZE" :align-desktop="HEADER_TEXT_ALIGN">
            {{ title }}
        </HeaderText>
        <BaseLinkStatic class="category-link" :path="link" :label="$t('Check')" :title="title" />
    </div>
</template>

<script>
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import {
    HeaderText,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_ALIGNS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingCategorySliderHeading',

    components: {
        HeaderText,
        BaseLinkStatic,
    },

    props: {
        link: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES.M;
        this.HEADER_TEXT_ALIGN = HEADER_TEXT_ALIGNS.CENTER;
    },
};
</script>

<style lang="scss" scoped>
.marketing-category-slider-heading {
    .category-link {
        @apply underline;
    }
}
</style>
