<template>
    <section class="marketing-discount-banner-heading" :class="theme">
        <div class="column-heading">
            <HeaderText
                :size="HEADER_TEXT_SIZE.L"
                :size-desktop="HEADER_TEXT_SIZE.XL"
                :variant="headerTextVariant"
            >
                {{ heading }}
            </HeaderText>
        </div>

        <div v-if="rules" class="column-rules">
            <div class="rules-click-container" @click="$emit('rules-info-click')">
                <span class="rules-text">{{ $t('Promotion rules') }}</span>
                <SvgIcon width="24px" height="24px" class="rules-icon">
                    <Info />
                </SvgIcon>
            </div>
        </div>
    </section>
</template>

<script>
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import Info from '@static/icons/24px/info.svg?inline';
import { THEMES } from '@types/MarketingDiscountBanner';

import {
    HeaderText,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_VARIANTS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingDiscountBannerHeading',

    components: {
        HeaderText,
        SvgIcon,
        Info,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        rules: {
            type: Boolean,
            default: false,
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: value => Object.values(THEMES).includes(value),
        },
    },

    computed: {
        isThemeDark() {
            return this.theme === THEMES.THEME_DARK;
        },

        headerTextVariant() {
            return this.isThemeDark ? HEADER_TEXT_VARIANTS.ALTERNATIVE : HEADER_TEXT_VARIANTS.BASIC;
        },
    },

    beforeCreate() {
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES;
    },
};
</script>

<style lang="scss" scoped>
.marketing-discount-banner-heading {
    @apply flex items-start justify-between w-full;

    &.light {
        .rules-text {
            @apply text-gray1;
        }
    }

    &.dark {
        .rules-text {
            @apply text-light;
        }

        .rules-icon {
            @apply text-light;
        }
    }

    .column-rules {
        @apply flex justify-end ml-3;
    }

    .rules-click-container {
        @apply cursor-pointer inline-flex whitespace-nowrap;
    }

    .rules-text {
        @apply hidden mr-12p;
    }

    @screen lg {
        .column-heading {
            @apply w-2/3;
        }

        .column-rules {
            @apply w-1/3 ml-0;
        }

        .rules-text {
            @apply inline;
        }
    }
}
</style>
