<template>
    <div class="marketing-image-tiles-simple-slider">
        <ContainerContent :align-left="true" class="slider-container">
            <HeaderText
                :tag="HEADER_TEXT_TAG"
                :size="HEADER_TEXT_SIZE_MOBILE"
                :size-desktop="HEADER_TEXT_SIZE_DESKTOP"
                class="heading"
            >
                {{ heading }}
            </HeaderText>

            <SimpleSlider :items-count="SLIDES_COUNT" :change-slide-by-one="false" class="slider">
                <template #slides>
                    <slot />
                </template>
            </SimpleSlider>
        </ContainerContent>
    </div>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';

import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingImageTilesSimpleSlider',

    components: {
        HeaderText,
        ContainerContent,
        SimpleSlider,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.SLIDES_COUNT = 6;
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H2;
        this.HEADER_TEXT_SIZE_MOBILE = HEADER_TEXT_SIZES.M;
        this.HEADER_TEXT_SIZE_DESKTOP = HEADER_TEXT_SIZES.XL;
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$item-heading-margin-top: 8px;
$item-heading-line-height: $tailwindcss-lineHeight-s;
$item-picture-height: calc(100% - #{$item-heading-line-height} - #{$item-heading-margin-top});
$navigation-buttons-height: 42px;

$navigation-top: calc(#{$item-picture-height} / 2 - #{math.div($navigation-buttons-height, 2)});

.marketing-image-tiles-simple-slider {
    .heading {
        @apply ml-3;
    }

    .slider-container {
        @apply px-0;
    }

    .slider {
        @apply mt-3;
    }

    &:deep() {
        .navigation-buttons {
            top: $navigation-top;
        }
    }

    @screen lg {
        .slider-container {
            @apply px-5;
        }
    }

    @screen container {
        .heading {
            @apply ml-0;
        }
    }
}
</style>
