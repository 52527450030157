<template>
    <div class="marketing-homepage-section-title">
        <HeaderText
            class="title"
            :size="HEADER_TEXT_SIZE.M"
            :size-desktop="HEADER_TEXT_SIZE.L"
            :align="HEADER_TEXT_ALIGN"
        >
            {{ title }}
        </HeaderText>

        <HeaderText
            class="subtitle"
            :size="HEADER_TEXT_SIZE.XL"
            :size-desktop="HEADER_TEXT_SIZE.XXL"
            :align="HEADER_TEXT_ALIGN"
        >
            {{ subtitle }}
        </HeaderText>
    </div>
</template>

<script>
import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_ALIGNS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'MarketingHomepageSectionTitle',

    components: {
        HeaderText,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        subtitle: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H1;
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES;
        this.HEADER_TEXT_ALIGN = HEADER_TEXT_ALIGNS.CENTER;
    },
};
</script>

<style lang="scss" scoped>
$letter-spacing-space: $tailwindcss-customSizes-10p;

.marketing-homepage-section-title {
    @apply flex flex-col items-center px-ds-4;

    .title {
        @apply pb-ds-2 relative uppercase;
        letter-spacing: $letter-spacing-space;
        text-indent: $letter-spacing-space;

        &::before {
            @apply absolute h-1p inset-x-0 bottom-0 mx-auto bg-gray1 w-11/20;
            content: '';
        }
    }

    .subtitle {
        @apply mt-ds-2;
    }

    @screen lg {
        @apply self-center px-ds-0;

        .title {
            @apply pb-ds-4;
        }

        .subtitle {
            @apply mt-ds-4;
        }
    }
}
</style>
