<template>
    <ButtonLarge
        ref="copyToClipboardButton"
        :variant="variant"
        :show-left-slot="false"
        :class="{ 'prevent-click': isPending }"
        class="copy-to-clipboard-button"
        @click="copyDiscountCodeToClipboard()"
    >
        {{ buttonLabel }}
        <transition :name="transitionName" mode="out-in">
            <Icon
                :key="isCopied ? 'check' : 'copy'"
                :icon="iconComponent"
                class="icon"
                width="24px"
                height="24px"
            />
        </transition>
    </ButtonLarge>
</template>

<script>
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import Copy from '@static/icons/16px/copy.svg?inline';
import Check from '@static/icons/16px/check2.svg?inline';

import { copyToClipboard } from '@assets/clipboard';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';
import { Icon } from '@modivo-ui/components/Icon/v1';

const CHANGE_LABEL_TO_COPIED_AFTER = 100;
const RESET_TO_DEFAULT_STATE_AFTER = 900;

export default {
    name: 'CopyToClipboardButton',

    components: {
        Icon,
        ButtonLarge,
    },

    props: {
        valueToCopy: {
            type: String,
            required: true,
        },

        beforeCopyLabel: {
            type: String,
            default: '',
        },

        afterCopyLabel: {
            type: String,
            default: '',
        },

        variant: {
            type: String,
            default: BUTTON_LARGE_VARIANTS.SECONDARY,
            validator: value => Object.values(BUTTON_LARGE_VARIANTS).includes(value),
        },
    },

    data() {
        return {
            isPending: false,
            isCopied: false,
        };
    },

    computed: {
        iconComponent() {
            return this.isCopied ? Check : Copy;
        },

        transitionName() {
            return this.isCopied ? 'fade' : '';
        },

        buttonLabel() {
            return this.isCopied
                ? this.afterCopyLabel || this.$t('Copied')
                : this.beforeCopyLabel || this.$t('Copy to clipboard');
        },
    },

    methods: {
        copyDiscountCodeToClipboard() {
            this.isPending = true;
            const { valueToCopy } = this;

            try {
                copyToClipboard(valueToCopy);

                setTimeout(() => {
                    this.isCopied = true;

                    this.$emit('value-copied');
                }, CHANGE_LABEL_TO_COPIED_AFTER);

                setTimeout(() => {
                    this.isCopied = false;
                    this.isPending = false;
                }, RESET_TO_DEFAULT_STATE_AFTER);
            } catch (err) {
                this.isCopied = false;
                this.isPending = false;

                this.$errorHandler.captureError(
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'copyToClipboard',
                    },
                    {
                        valueToCopy,
                    }
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.copy-to-clipboard-button {
    &:deep(.label) {
        @apply flex items-center;

        .icon {
            @apply ml-ds-4;
        }
    }

    .fade-enter-active {
        transition: opacity 0.3s ease 0.2s;
    }

    .fade-enter {
        @apply opacity-0;
    }
}
</style>
