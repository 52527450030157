<template>
    <div
        v-if="isCounterVisible"
        :id="timerId"
        :class="theme"
        :style="customStyles"
        class="marketing-hero-timer"
    >
        <div class="timer-wrapper">
            <BaseParagraph class="timer-info-text">
                {{ $t('On sale from') }}
                {{ startDateLocaleString.date }}
            </BaseParagraph>
            <CountdownTimer
                ref="timer"
                :timer-id="timerId"
                :timestamp="startDateCountdownFormat"
                @hide-timer-section="hideTimerSection($event)"
            />
        </div>

        <ButtonSmall
            v-if="remindButtonLink"
            :variant="buttonVariant"
            :to="remindButtonLink"
            target="_blank"
            class="remind-button"
        >
            {{ reminderButtonText }}
        </ButtonSmall>
    </div>
</template>

<script>
import { THEMES, THEME_LIGHT } from '@types/MarketingBanner';

import { createGoogleCalendarLink } from '@assets/google-calendar-link';
import { checkIfExistsInValuesMap } from '@assets/props';

import Countdown from '@mixins/Countdown';
import XlBreakpointMediaQuery from '@mixins/XlBreakpointMediaQuery';

import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import CountdownTimer from '@molecules/CountdownTimer/CountdownTimer';

import { ButtonSmall, BUTTON_SMALL_VARIANTS } from '@modivo-ui/components/ButtonSmall/v2';

export default {
    name: 'MarketingHeroTimer',

    components: {
        BaseParagraph,
        CountdownTimer,
        ButtonSmall,
    },

    mixins: [Countdown, XlBreakpointMediaQuery],

    props: {
        timerId: {
            type: String,
            default: '',
        },

        date: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        customBackground: {
            type: String,
            default: '',
        },

        eventTitle: {
            type: String,
            default: '',
        },

        eventDates: {
            type: String,
            default: '',
        },

        eventDetails: {
            type: String,
            default: '',
        },

        eventAddress: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isCounterVisible: false,
        };
    },

    computed: {
        buttonVariant() {
            return this.theme === THEME_LIGHT
                ? BUTTON_SMALL_VARIANTS.SECONDARY_ALTERNATIVE
                : BUTTON_SMALL_VARIANTS.SECONDARY;
        },

        reminderButtonText() {
            return this.isXlBreakpointMediaQuery ? this.$t('Set reminder') : this.$t('Remind');
        },

        // eslint-disable-next-line vue/no-unused-properties
        startDate() {
            return new Date(this.date);
        },

        remindButtonLink() {
            if (!this.eventTitle && !this.eventDates) {
                return '';
            }

            return createGoogleCalendarLink(
                this.eventTitle,
                this.eventDates,
                this.eventDetails,
                this.eventAddress
            );
        },

        customStyles() {
            return this.customBackground ? { background: this.customBackground } : {};
        },
    },

    mounted() {
        this.isCounterVisible = this.timerId && this.date && this.beforeEvent;
    },

    methods: {
        hideTimerSection(element) {
            if (element === this.$refs.timer.$refs[this.timerId]) {
                this.isCounterVisible = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-hero-timer {
    @apply absolute bottom-0 flex justify-between items-center py-2 px-3 w-full;
    backdrop-filter: blur(8px);

    &.light {
        @apply text-light bg-dark bg-opacity-60;

        .timer-info-text {
            @apply text-light;
        }
    }

    &.dark {
        @apply text-dark bg-light bg-opacity-60;

        .timer-info-text {
            @apply text-dark;
        }
    }

    .timer-wrapper {
        @apply flex flex-col;
    }

    .timer-info-text {
        @apply text-xs leading-xxs font-semibold;
    }

    .remind-button {
        @apply px-2 text-center ml-10p;
    }

    @screen lg {
        @apply pt-1 pb-3 px-4;

        .timer-info-text {
            @apply text-s leading-m mb-1;
        }

        .remind-button {
            @apply self-end px-4 ml-3;
        }
    }

    @screen xl {
        @apply px-4 py-10p;

        .timer-info-text {
            @apply mb-2;
        }

        .remind-button {
            @apply px-30p mb-10p;
        }
    }
}
</style>
