<template>
    <section
        class="marketing-discount-banner-content"
        :class="[theme, showDescription ? 'with-description' : '']"
    >
        <BaseParagraph v-if="showDescription" class="column-description description">
            {{ description }}
        </BaseParagraph>

        <div class="column-buttons">
            <ButtonLarge
                v-if="productsUrl && productsButtonLabel"
                :to="productsUrl"
                :variant="buttonVariant"
                class="go-to-products-button"
            >
                {{ productsButtonLabel }}
            </ButtonLarge>

            <CopyToClipboardButton
                v-if="discountCode && !description"
                :value-to-copy="discountCode"
                :before-copy-label="$t('Copy discount code: {discountCode}', { discountCode })"
                :after-copy-label="$t('Discount code copied')"
                :variant="copyToClipboardButtonVariant"
                class="copy-discount-code-button"
            />
        </div>
    </section>
</template>

<script>
import { THEMES } from '@types/MarketingDiscountBanner';

import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import CopyToClipboardButton from '@molecules/CopyToClipboardButton/CopyToClipboardButton';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingDiscountBannerContent',

    components: {
        ButtonLarge,
        BaseParagraph,
        CopyToClipboardButton,
    },

    props: {
        description: {
            type: String,
            default: '',
        },

        productsButtonLabel: {
            type: String,
            required: true,
        },

        productsUrl: {
            type: String,
            required: true,
        },

        discountCode: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: value => Object.values(THEMES).includes(value),
        },
    },

    computed: {
        showDescription() {
            return this.description && !this.discountCode;
        },

        buttonVariant() {
            return this.theme === THEMES.THEME_LIGHT
                ? BUTTON_LARGE_VARIANTS.SECONDARY
                : BUTTON_LARGE_VARIANTS.SECONDARY_ALTERNATIVE;
        },

        copyToClipboardButtonVariant() {
            return this.theme === THEMES.THEME_LIGHT
                ? BUTTON_LARGE_VARIANTS.SECONDARY
                : BUTTON_LARGE_VARIANTS.SECONDARY_ALTERNATIVE;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-discount-banner-content {
    @apply w-full flex flex-col;

    &.light {
        .description {
            @apply text-gray1;
        }
    }

    &.dark {
        .description {
            @apply text-light;
        }
    }

    .column-buttons {
        @apply flex flex-col items-end;
    }

    .description {
        @apply text-s leading-s mt-3;
    }

    .go-to-products-button {
        @apply w-full mt-3 justify-center;
    }

    .copy-discount-code-button {
        @apply w-full mt-3 justify-between;
    }

    @screen md {
        .column-buttons {
            @apply flex-row;
        }

        .go-to-products-button {
            @apply w-auto;
        }

        .copy-discount-code-button {
            @apply w-auto ml-3;
        }
    }

    @screen lg {
        @apply flex-row items-end;

        &.with-description {
            .column-buttons {
                @apply w-1/3 justify-end;
            }
        }

        .column-description {
            @apply w-2/3;
        }

        .column-buttons {
            @apply w-full flex;
        }
    }
}
</style>
