<template>
    <ButtonIcon
        v-if="onlyIcon"
        :data-test-id="ADD_TO_WISHLIST_BUTTON"
        :variant="BUTTON_ICON_VARIANT"
        :disabled="isLoading"
        :class="classes"
        @click.prevent.stop="onClick()"
    >
        <Icon :icon="icon" />
    </ButtonIcon>
    <ButtonComponent
        v-else
        :data-test-id="ADD_TO_WISHLIST_BUTTON"
        :variant="BUTTON_COMPONENT_VARIANT"
        :disabled="isLoading"
        :class="classes"
        @click.prevent.stop="onClick()"
    >
        {{ title }}
        <template #icon>
            <Icon :icon="icon" />
        </template>
    </ButtonComponent>
</template>

<script>
import { ADD_TO_WISHLIST_BUTTON } from '@types/AutomaticTestIDs';

import { Favourite, FavouriteOn } from '@modivo-ui/icons/v1/other';

import { Icon } from '@modivo-ui/components/Icon/v1';

import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@modivo-ui/components/ButtonComponent/v1';
import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';

export default {
    name: 'ButtonAddToWishlist',

    components: {
        ButtonIcon,
        ButtonComponent,
        Icon,
    },

    props: {
        onlyIcon: {
            type: Boolean,
            default: false,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },

        isAdded: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        title() {
            return this.$t(`${this.isLoading ? 'Loading...' : 'Wishlist'}`);
        },

        icon() {
            return this.isAdded ? FavouriteOn : Favourite;
        },

        classes() {
            return ['button-add-to-wishlist', { added: this.isAdded }];
        },
    },

    beforeCreate() {
        this.ADD_TO_WISHLIST_BUTTON = ADD_TO_WISHLIST_BUTTON;
        this.BUTTON_COMPONENT_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY;
        this.BUTTON_ICON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.ButtonComponent = ButtonComponent;
        this.ButtonIcon = ButtonIcon;
    },

    methods: {
        onClick() {
            this.$emit('toggle');
        },
    },
};
</script>

<style lang="scss" scoped>
.button-add-to-wishlist {
    &.added {
        &:deep(.icon) {
            @apply text-ui-text-primary-accent;
        }
    }
}
</style>
