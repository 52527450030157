<template>
    <div class="marketing-influencer-headings">
        <BaseHeading class="heading">{{ heading }}</BaseHeading>
        <BaseHeading theme="h5" class="sub-heading">
            {{ subHeading }}
        </BaseHeading>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

export default {
    name: 'MarketingInfluencerHeadings',

    components: {
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
        subHeading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-influencer-headings {
    @apply flex flex-col mb-3;

    .heading {
        @apply text-ds-content-accent;
    }

    @screen md {
        @apply -mb-5;

        max-width: 45%;

        .heading {
            @apply text-m leading-m;
        }

        .sub-heading {
            @apply text-l leading-l mt-1;
        }
    }

    @screen lg {
        .sub-heading {
            @apply text-xl leading-xl mt-2;
        }
    }
}
</style>
