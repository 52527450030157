<template>
    <section class="marketing-promo-categories">
        <Tabs
            :items="tabs"
            :selected-item-id="selectedTabIndex"
            :class="tabsClass"
            class="tabs-list"
            @change="selectTab($event)"
        />
        <div v-for="index in slidersCount" :key="index" class="container">
            <slot v-if="selectedTabIndex === index - 1" :name="`container${index}`" />
        </div>
    </section>
</template>

<script>
import { THEMES } from '@types/MarketingSection';

import { checkIfExistsInValuesMap } from '@assets/props';

import { Tabs } from '@modivo-ui/components/Tabs/v2';

export default {
    name: 'MarketingPromoCategories',

    components: {
        Tabs,
    },

    props: {
        tabTitle1: {
            type: String,
            default: null,
        },

        tabTitle2: {
            type: String,
            default: null,
        },

        tabTitle3: {
            type: String,
            default: null,
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },
    },

    data() {
        return {
            selectedTabIndex: 0,
        };
    },

    computed: {
        tabs() {
            const { tabTitle1, tabTitle2, tabTitle3 } = this;
            const tabsArray = [tabTitle1, tabTitle2, tabTitle3];

            return tabsArray
                .filter(tab => !!tab)
                .map((tab, id) => ({
                    id,
                    label: tab,
                }));
        },

        slidersCount() {
            return Object.keys(this.$slots).length;
        },

        tabsClass() {
            return this.theme;
        },
    },

    methods: {
        selectTab(index) {
            this.selectedTabIndex = index;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-promo-categories {
    .tabs-list {
        @apply mb-ui-3;
    }

    :deep(.tabs) {
        &.dark {
            @apply bg-ds-container-reverse;

            .tabs-item-container {
                @apply bg-ds-container-transparent;
                @apply text-ds-content-disabled;

                &.selected {
                    @apply text-ds-content-reverse;

                    &:after {
                        @apply bg-ds-overlay-neutral;
                    }
                }

                &:hover {
                    &::before {
                        @apply bg-ds-overlay-neutral;
                    }
                }

                &:active:not(&.selected) {
                    &:before {
                        @apply bg-ds-overlay-neutral;
                    }
                }
            }

            .tabs-divider {
                @apply bg-ds-content-tertiary;
            }
        }
    }

    .content {
        @apply hidden;
        @apply outline-none;

        &.show {
            @apply block;
        }
    }

    .container {
        @apply w-ui-percent-100;
        @apply max-w-ui-percent-100;
    }
}
</style>
