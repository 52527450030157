<template>
    <div tabindex="1" class="base-tag" :disabled="disabled">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'BaseTag',
    props: {
        /**
         * Disabled attribute.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.base-tag {
    @apply pl-3 rounded-ds-control-medium leading-s border-solid border-1 border-gray1 text-s;
    @apply text-gray1 font-book inline-flex items-center cursor-default pt-1 pb-1;

    &:hover {
        @apply border-gray3;
    }

    &:focus {
        @apply outline-none shadow-7;
    }

    &[disabled] {
        @apply bg-inactive text-label border-border pointer-events-none;
        &:hover {
            @apply border-1;
        }

        .close-button {
            span {
                svg {
                    @apply opacity-40;
                }
            }
        }
    }
}
</style>
