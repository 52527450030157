<template>
    <div class="promo-action-redirect" :class="theme" :style="{ backgroundColor }">
        <BaseHeading v-if="heading" class="heading" theme="h5">
            {{ heading }}
        </BaseHeading>

        <BaseParagraph v-if="text" class="text">
            {{ text }}
        </BaseParagraph>

        <ButtonLarge
            v-if="buttonLink && buttonText"
            :to="buttonLink"
            :variant="buttonLargeVariant"
            class="button"
        >
            {{ buttonText }}
        </ButtonLarge>
    </div>
</template>

<script>
import { THEMES, BUTTON_THEMES } from '@types/PromoActionRedirect';

import { checkIfExistsInValuesMap } from '@assets/props';
import { getDesignSystemButtonVariant } from '@assets/base-button-mapping';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import { ButtonLarge } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'PromoActionRedirect',

    components: {
        BaseHeading,
        BaseParagraph,
        ButtonLarge,
    },

    props: {
        heading: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        buttonText: {
            type: String,
            default: '',
        },

        buttonLink: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        buttonTheme: {
            type: String,
            default: BUTTON_THEMES.THEME_PRIMARY,
            validator: checkIfExistsInValuesMap(BUTTON_THEMES),
        },
    },

    computed: {
        buttonLargeVariant() {
            return getDesignSystemButtonVariant(this.buttonTheme);
        },
    },
};
</script>

<style lang="scss" scoped>
.promo-action-redirect {
    @apply text-center p-ds-7;

    .text {
        @apply text-center text-s leading-s text-gray1 mt-ds-2;
    }

    .button {
        @apply mt-ds-6;
    }

    &.light {
        .heading,
        .text {
            @apply text-light;
        }
    }

    @screen lg {
        .text {
            @apply text-r leading-r;
        }
    }
}
</style>
