import { createNamespacedHelpers } from 'vuex';

import { NEWSLETTER_RELEASER_WISHLIST_LISTING } from '@configs/modals';
import { isNewsletterTooltipTestOn } from '@configs/abtest-newsletter-tooltip';

import { isModivoClubGoldCardSku } from '@modivo-club-assets/modivo-club';

import NewsletterTooltip from '@mixins/NewsletterTooltip';

const { mapActions: mapCustomerActions, mapGetters: mapCustomerGetters } = createNamespacedHelpers(
    'customer'
);

export default {
    props: {
        index: {
            type: Number,
            default: 0,
        },

        actionField: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLoading: false,
        };
    },

    mixins: [NewsletterTooltip],

    computed: {
        ...mapCustomerGetters(['isOnWishlist']),

        isAdded() {
            return this.isOnWishlist(this.product.sku);
        },
    },

    methods: {
        ...mapCustomerActions(['addToWishlist', 'removeFromWishlist']),

        async toggle(shouldEnableNewsletterTooltip = false) {
            const {
                addToWishlist,
                removeFromWishlist,
                isAdded,
                product,
                index,
                actionField = '',
            } = this;

            if (isModivoClubGoldCardSku(product.sku)) {
                return;
            }

            this.isLoading = true;

            const productMapped = {
                ...product,
                pageType: this.$route?.name || '',
                index,
                actionField,
            };

            if (isAdded) {
                await removeFromWishlist(productMapped);
            } else {
                const result = await addToWishlist(productMapped);

                if (
                    result &&
                    shouldEnableNewsletterTooltip &&
                    isNewsletterTooltipTestOn(this.$abTests)
                ) {
                    this.setIsNewsletterTooltipEnabled(NEWSLETTER_RELEASER_WISHLIST_LISTING);
                }
            }

            this.isLoading = false;
        },
    },
};
